import { render, staticRenderFns } from "./MedyaOrtamItem.vue?vue&type=template&id=3ee03a8d&scoped=true&"
import script from "./MedyaOrtamItem.vue?vue&type=script&lang=js&"
export * from "./MedyaOrtamItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ee03a8d",
  null
  
)

export default component.exports