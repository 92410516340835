<template>
  <li class="list-group-item">
    <ElementItem
      class="ana-item"
      :item="item"
      @handlerUpdate="$emit('handlerUpdate', $event)"
    />
  </li>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import ElementItem from "./ElementItem.vue";
export default defineComponent({
  components: {
    ElementItem,
  },
  props: {
    item: {
      type: Object,
      requied: true,
    },
  },
  setup(_, context) {
    const expo = {};
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
