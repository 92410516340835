<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Medya Ortam'"
    :total="rows.length"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
  >
    <template v-slot:leftBody>
      <b-alert
        variant="warning"
        show
        class="text-center m-3"
        v-if="!rows.length"
      >
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>

      <ul v-else tag="ul" class="list-group">
        <MedyaOrtamItem
          v-for="(item, index) in rows"
          :key="index"
          :item="item"
          @handlerUpdate="handlerUpdate($event)"
        />
      </ul>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <!-- <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert> -->
        <!-- <MedyaOrtamEkle
          v-else
          :updateData="updateData"
          @show="show = $event"
          @updateRefresh="handlerUpdatedData"
          @handlerClose="type = $event"
        /> -->
        <transition mode="out-in">
          <router-view :updateData="updateData" />
        </transition>
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from "@/components/cards/BListCard.vue";
import { ref, watch, defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import MedyaOrtamEkle from "./component/MedyaOrtamEkle.vue";
import MedyaOrtamItem from "./component/MedyaOrtamItem.vue";
import { useToast } from "vue-toastification/composition";
import draggable from "vuedraggable";
import { langs } from "@/libs/languages";
import { useRouter } from "@/libs/utils";
export default defineComponent({
  components: {
    draggable,
    BListCard,
    MedyaOrtamEkle,
    MedyaOrtamItem,
  },
  setup() {
    const expo = {};

    const { router } = useRouter();
    const toast = useToast();
    expo.search = ref(null);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.rows = computed(() => store.getters.getMedyaOrtam);

    expo.form = ref({
      k_no: null,
      baslik: "Medya Ortam",
    });

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch("medyaOrtamListele");
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = () => {
      expo.show.value = true;
      store
        .dispatch("medyaOrtamEkle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Ekleme Başarılı.", { position: "bottom-left" });
            expo.updateData.value = res.data.data;
            router.push({ name: "medya-ortam-ekle" });
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      router.push({
        name: "medya-ortam-guncelle",
        params: { k_no: event.k_no },
      });
      expo.show.value = false;
    };

    watch(
      expo.rows,
      (val) => {
        console.log(val);
      },
      { immediate: true }
    );

    // watch(expo.search, (val) => {
    //   if (val.length > 0) {
    //     const search = val.toLocaleLowerCase("TR").trim();
    //     let filteredData = store.getters.getMedyaOrtam;
    //     filteredData = filteredData.filter((rows) => {
    //       return rows.icerik["tr"].baslik
    //         .toLocaleLowerCase("TR")
    //         .includes(search);
    //     });
    //     expo.rows.value = filteredData;
    //   } else {
    //     expo.rows.value = store.getters.getMedyaOrtam;
    //   }
    // });

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
